import React, { useState } from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "./layout"
import configs from "../../site-config"

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(true)
  return (
    <StaticQuery
      query={query}
      render={data => (
        <Layout>
          <nav className="flex items-center justify-between flex-wrap p-6 border-b-2 border-gray-200">
            <div className="flex items-center flex-shrink-0 text-white mr-6">
              <a href="/" className="">
                <Img
                  fluid={data.headerIcon.childImageSharp.fluid}
                  className="headerIcon rounded-lg"
                />
              </a>

              <a
                href="/"
                className="invisible sm:visible font-bold text-brand-dark-blue"
              >
                {configs.app_name}
              </a>
            </div>
            <div className="block sm:hidden">
              <button
                onClick={() => toggleExpansion(!isExpanded)}
                className="flex items-center px-3 py-2 border rounded text-brand-dark-blue border-brand-dark-blue hover:text-white hover:border-white"
              >
                <svg
                  className="fill-current h-6 w-6"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
            <div
              className={`${
                isExpanded ? `block` : `hidden`
              } w-full block flex-grow sm:flex sm:items-center sm:w-auto justify-end`}
            >
              <div className="text-2xl">
                <Link
                  to={`/Contact/`}
                  href="#responsive-header"
                  className="block mt-4 sm:inline-block sm:mt-0 text-teal-200 hover:text-white mr-4"
                >
                  Contact
                </Link>
              </div>
            </div>
          </nav>
        </Layout>
      )}
    />
  )
}

export const query = graphql`
  query {
    headerIcon: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appIconLarge: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "headerimage.png" }) {
      childImageSharp {
        fluid(maxHeight: 714) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Header
